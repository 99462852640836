import { render, staticRenderFns } from "./hotelOrder.vue?vue&type=template&id=69fbb712&scoped=true&"
import script from "./hotelOrder.vue?vue&type=script&lang=js&"
export * from "./hotelOrder.vue?vue&type=script&lang=js&"
import style0 from "./hotelOrder.vue?vue&type=style&index=0&id=69fbb712&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fbb712",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src736759391/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/364953906322/ap-northeast-1/84d36ee7-16b8-4e80-bff0-7e6a249efe8b/OwlTing/owlnest_booking_engine/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('69fbb712', component.options)
    } else {
      api.reload('69fbb712', component.options)
    }
    module.hot.accept("./hotelOrder.vue?vue&type=template&id=69fbb712&scoped=true&", function () {
      api.rerender('69fbb712', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Complete/components/hotelOrder.vue"
export default component.exports