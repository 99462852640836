<template>
  <main class='complete mt-40'>
    <loading v-if='isOrderLoading' />
    <div v-else>
      <div
        v-if='!hasOrder'
        class="container mb-80">
        <blockCard>
          <div
            id="invalid-order"
            class='p-24 flex flex-col items-center'>
            <img src="@/assets/img/hotel-img-grey.png" alt="" class='mb-32'>
            <div class="empty-notice flex items-center">
              <i class='owl-status-triangle-warning text-orange text-2xl'></i>
              <p class='text-2xl font-medium text-grey-darkest'>{{ $t('checkNoOrder') }}</p>
            </div>
          </div>
        </blockCard>
      </div>

      <div
        v-else
        class="container">
        <paymentStatusWithCoverImg
          :payment-method-type="typeOfPaymentMethod"
          :first-payment-status="firstPaymentStatus"
          :payment-status="paymentStatus"
        />
        <blockCard v-if="orderInfo.atm_expired_time_at && isATM && (paymentStatus === 'unpaid' || paymentStatus === 'deposit_unpaid')" class="bg-orange-lightest">
          <div class="notice flex items-center mb-24">
            <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
            <span class='text-grey-darkest text-sm'>{{ $t('ifNotPaidAt[0]') }} <mark class='bg-transparent text-blue font-bold'> {{ orderInfo.atm_expired_time_at }}</mark> {{ $t('ifNotPaidAt[1]') }}</span>
          </div>
          <p class='leading-normal text-grey-darkest text-sm whitespace-pre-wrap'>{{ $t('atmMsg') }}</p>
        </blockCard>
        <blockCard v-if="orderInfo.atm_expired_time_at && isAtmHotel && (paymentStatus === 'unpaid' || paymentStatus === 'deposit_unpaid')" class="bg-orange-lightest">
          <div class="notice flex items-center mb-24">
            <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
            <span class='text-grey-darkest text-sm whitespace-pre-wrap'>{{ $t('ifIsAtmHotelAndNotPaidAt[0]') }} <mark class='bg-transparent text-blue font-bold'> {{ orderInfo.atm_expired_time_at }}</mark> {{ $t('ifIsAtmHotelAndNotPaidAt[1]') }}</span>
          </div>
          <p class='leading-normal text-grey-darkest text-sm whitespace-pre-wrap'>{{ $t('ifIsAtmHotelAndAtmMsg') }}</p>
        </blockCard>
        <div v-if="isCancelButton" class="text-right my-16 p-16 border border-grey-lighter rounded">
          <button class="btn btn-sm btn-ghost btn-orange" @click="onCancelbuttonClick" :disabled="isCancelingOrder">
            <template v-if="isCancelingOrder">
              {{ $t('isCanceling') }}
            </template>
            <template v-else>
                {{ $t('cancelOrder') }}
            </template>
          </button>
        </div>

        <!-- 轉帳資訊 -->
        <blockCard
          v-if="showTransferInfo"
          noPadding
          class="transfer-info">
          <div class='transfer-info__detail py-24 lg:p-24 border-b-1 border-grey-lighter'>
            <h4 class='mb-24'>{{ $t('transferInformation') }}</h4>
            <div class="flex-table mb-24">
              <div class="flex-table__row">
                <div class="flex-table__row__cell">{{ $t('transferBank') }}：</div>
                <div v-if="isAtmHotel" class="flex-table__row__cell">
                  {{ `${orderInfo.atm_bank_code} ${orderInfo.atm_bank_name}` }}<br>
                  ( {{ `${orderInfo.atm_bank_sub_code} ${orderInfo.atm_bank_sub_name}` }} )
                </div>
                <div v-else class="flex-table__row__cell">{{ $t('cathayBank') }}</div>
              </div>
              <div class="flex-table__row">
                <div class="flex-table__row__cell">{{ $t('accountName') }}：</div>
                <div v-if="isAtmHotel" class="flex-table__row__cell">{{ orderInfo.atm_account_name }}</div>
                <div v-else class="flex-table__row__cell">{{ $t('owltingTraveAgency') }}</div>
              </div>
              <div class="flex-table__row">
                <div class="flex-table__row__cell">{{ $t('accountNumber') }}：</div>
                <div class="flex-table__row__cell">{{ orderInfo.atm_account }}</div>
              </div>
              <div class="flex-table__row" v-if="orderInfo.atm_expired_time_at">
                <div class="flex-table__row__cell">{{ $t('transferPeriod') }}：</div>
                <div class="flex-table__row__cell">{{ orderInfo.atm_expired_time_at }}</div>
              </div>
            </div>

            <div class="transfer-info__detail__total-amount flex items-end">
              <label class='text-base text-grey-darkest mr-8'>{{ $t('transferAmount') }} <span v-if="isDeposit">（{{ $t('deposit') }}）</span>：</label>
              <span class='text-xl text-blue font-bold'>{{ orderInfo.currency }} {{ ATMTotal | numeral }}</span>
            </div>
            <template v-if="isAtmHotel">
              <h4 class="pt-16 mt-24 border-t border-grey-lighter">{{ $t('paymentConfirm') }}</h4>
              <div class="transfer-info__detail__total-amount flex items-end mt-8">
                <div class="form-group">
                  <div class="mt-8 mb-16">
                    <div class="text-base text-grey-darkest mr-8 mb-8">
                      {{ $t('accountLast5Numbers') }}
                      <span
                        v-if="validation.firstError('atmPaidAccount')"
                        class="text-red text-sm">*{{ $t('errorMsg.length', [5]) }}</span>
                    </div>
                    <input v-if="paymentStatus === 'deposit_unpaid' || paymentStatus === 'unpaid'" v-model="atmPaidAccount" name="atmPaidAccount" class="input" type="tel" maxlength="5" :disabled="isOnClikcCheckAccount">
                    <div v-if="paymentStatus === 'not_finish' || paymentStatus === 'paid'" class="input text-grey-darker italic text-sm">
                      {{ atmPaidAccount }}
                    </div>
                  </div>
                  <div class="my-16">
                    <div class="text-base text-grey-darkest mr-8 mb-8">
                      {{ $t('paymentDate') }}
                      <span
                        v-if="validation.firstError('atmPaidDate')"
                        class="text-red text-sm">*{{ $t('errorMsg.empty') }}</span>
                    </div>
                    <input v-if="paymentStatus === 'deposit_unpaid' || paymentStatus === 'unpaid'" v-model="atmPaidDate" name="atmPaidDate" class="input" type="date" :disabled="isOnClikcCheckAccount">
                    <div v-if="paymentStatus === 'not_finish' || paymentStatus === 'paid'" class="input text-grey-darker italic text-sm">
                      {{ atmPaidDate }}
                    </div>
                  </div>
                  <div class="my-16">
                    <div class="text-base text-grey-darkest mr-8 mb-8">
                      {{ $t('paymentAmount') }}
                      <span
                        v-if="validation.firstError('atmPaidTotal')"
                        class="text-red text-sm">*{{ $t('errorMsg.empty') }}</span>
                    </div>
                    <input v-if="paymentStatus === 'deposit_unpaid' || paymentStatus === 'unpaid'" v-model="atmPaidTotal" name="atmPaidTotal" class="input" type="number" :disabled="isOnClikcCheckAccount">
                    <div v-if="paymentStatus === 'not_finish' || paymentStatus === 'paid'" class="input text-grey-darker italic text-sm">
                      {{ atmPaidTotal }}
                    </div>
                  </div>
                  <div class="my-16">
                    <div class="text-base text-grey-darkest mr-8 mb-8">
                      {{ $t('captcha') }}
                      <span
                        v-if="validation.firstError('userCaptcha')"
                        class="text-red text-sm">*{{ $t('errorMsg.empty') }}</span>
                    </div>
                    <div class="flex items-center" v-if="paymentStatus === 'deposit_unpaid' || paymentStatus === 'unpaid'">
                      <div class="captcha" :class="{'captcha--loading': isCaptchaLoading}">
                        <img :src="captchaImg" class="captcha__img">
                      </div>
                      <button class="btn btn-text btn-grey" :disabled="isCaptchaLoading" @click="fetchCaptcha">
                        <i class="owl-arrow-update"></i>
                      </button>
                    </div>
                    <input v-if="paymentStatus === 'deposit_unpaid' || paymentStatus === 'unpaid'" v-model="userCaptcha" name="userCaptcha" class="input" :disabled="isOnClikcCheckAccount || isCaptchaLoading">
                  </div>
                  <button v-if="paymentStatus === 'deposit_unpaid' || paymentStatus === 'unpaid'" class="btn btn-sm btn-solid btn-orange px-16" @click="onClikcCheckAccount" :disabled="isOnClikcCheckAccount">
                    <span v-if="isOnClikcCheckAccount"><i class="owl-arrow-reload animate-spin"></i></span>
                    <span v-else>{{ $t('sent') }}</span>
                  </button>
                </div>
              </div>
            </template>
          </div>

          <div class="transfer-info__notice py-24 lg:p-24">
            <template v-if="isAtmHotel">
              <div class="notice flex items-center mb-8">
                <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
                <span class="mr-4" v-html="$t('provideAccountLast5NumbersAfterTransfer')"></span>
              </div>
            </template>
            <template v-else>
              <div class="notice flex items-center mb-8">
                <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
                <span class='text-grey-darkest text-sm'>{{ $t('transferAccountWarning') }}</span>
              </div>
              <div class="notice flex items-center">
                <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
                <span class='text-grey-darkest text-sm '>{{ $t('ifCancelWarning') }}</span>
              </div>
            </template>
          </div>
        </blockCard>

        <!-- 訂單資訊 -->
        <!-- <h2
          v-if='!isAtmOrAtmHotel && paymentStatus === "unpaid"'
          class='text-red-dark font-bold mb-16'>{{ $t('orderFormNotCompleted') }}</h2> -->
        <blockCard
          noPadding
          class="order-info">
          <div class='order-info__detail py-24 lg:p-24 border-b-1 border-grey-lighter'>
            <h4 class='mb-24'>{{ $t('orderInformation') }}</h4>

            <div class="order-info__detail__date-participant text-grey-darkest mb-32">
              <div class="order-info__detail__date-participant mb-16">
                <i class="owl-calendar mr-8 text-grey text-xl"></i>
                <span>{{ dateRange }}</span>
                <small class="ml-8 text-grey-dark">( {{ diffDays + 1 }} {{ $tc('day', diffDays + 1) }} {{ diffDays }} {{ $tc('night', diffDays) }} )</small>
              </div>
              <div class="order-info__detail__date-participant">
                <i class="owl-user-group mr-8 text-grey text-xl"></i>
                <span>{{ orderInfo.guest.adult }} {{ $tc('adult', orderInfo.guest.adult) }}</span>
                <span>, {{ orderInfo.guest.children }} {{ $tc('child', orderInfo.guest.children) }}</span>
                <span>, {{ orderInfo.guest.infant }} {{ $tc('infant', orderInfo.guest.infant) }}</span>
              </div>
            </div>

            <div class="template__info-group mb-24">
              <label>{{ $t('orderNumber') }}</label>
              <span>{{ orderInfo.order_serial }}</span>
            </div>

            <div class="template__info-group mb-24">
              <label>{{ $t('orderNights') }}</label>
              <span>{{ diffDaysCount }} {{ $tc('night', diffDaysCount) }}</span>
            </div>

            <div v-if="hasUsedCoupon" class="template__info-group mb-24">
              <label>{{ $t('couponApplied') }}</label>
              <div class="used-coupons flex">
                <div
                  v-for="(coupon, index) in orderInfo.coupons"
                  :key="coupon.code + index"
                  class="coupon-container w-full md:w-1/3">
                  <div class="coupon-detail border-1 border-grey-lightest shadow-lg p-16 font-medium">
                    <label>{{ $t('roomTotal') }}</label>
                    <span>- {{ couponDiscountValue(coupon) }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-info__detail__amounts flex flex-col md:flex-row">
              <!-- redmine #3601: 付款失敗 及 訂單取消 隱藏剩餘尾款 -->
              <!-- redmine #5691 只有代收需要顯示尾款 -->
              <div class="template__info-group" v-if="isShowRemainTotal">
                <!-- 尾款 -->
                <label>{{ $t('remainTotal') }}</label>
                <span>{{ orderInfo.currency }} <big class='text-blue font-bold'>{{ remainTotal | numeral }}</big></span>
              </div>

              <template v-if="typeOfPaymentMethod === 'collection'">
                <div class="template__info-group">
                  <label>{{ $t('amountPaid') }}</label>
                  <span>
                    {{ orderInfo.currency }}
                    {{ paidDepositTotal | numeral }}
                  </span>
                </div>
              </template>

              <div class="template__info-group">
                <!-- 總金額 -->
                <label>{{ $t('total') }}</label>
                <div class="flex flex-col md:flex-row">
                  <span>{{ orderInfo.currency }} {{ orderInfo.order_total | numeral }}</span>
                </div>
              </div>
            </div>
            <p v-if="hasExtraTaxFeeConfig" class="text-sm mt-16 text-grey-dark">
              <i class="owl-status-triangle-warning text-orange" />
              {{ $t('room_price_has_tax_included') }} {{ orderInfo.currency }} {{ totalExtraFee | numeral }}
            </p>
          </div>

          <div class='order-info__orderer py-24 lg:p-24'>
            <div class="template__info-group">
              <label>{{ $t('lastName') }}</label>
              <span>{{ orderInfo.lastname }}</span>
            </div>
            <div class="template__info-group">
              <label>{{ $t('firstName') }}</label>
              <span>{{ orderInfo.firstname }}</span>
            </div>
            <div class="template__info-group">
              <label>{{ $t('country') }}</label>
              <span>{{ countryName }}</span>
            </div>
            <div class="template__info-group">
              <label>{{ $t('phone') }}</label>
              <span>{{ orderInfo.phone }}</span>
            </div>
            <div class="template__info-group">
              <label>{{ $t('email') }}</label>
              <span>{{ orderInfo.email }}</span>
            </div>
            <!-- <div class="template__info-group">
              <label>LINE ID</label>
              <span>iiwiwiwiwi</span>
            </div> -->
          </div>
          <div v-if="orderInfo.order_requirements" class="text-grey-darker text-sm py-24 lg:p-24 border-t border-grey-lighter">
            <div class="template__info-group">
                <!-- 尾款 -->
                <label>{{ $t('orderRequirements') }}</label>
                <span>{{ orderInfo.order_requirements }}</span>
              </div>
          </div>
        </blockCard>

        <!-- 房型訂單 -->
        <div class="hotel-orders">
          <h4 class='py-24 font-bold'>{{ $t('roomTypeAndOrders') }}</h4>
          <blockCard noPadding>
            <div
              v-for="(room, index) in roomsGroupByRoomName"
              :key="index"
              class="hotel-orders__order py-24 lg:p-24"
            >
            <div v-for="(plan, index) in room" :key="`${plan.name}-${index}`">
              <h4 class='text-grey-darker mb-16'>
                {{ $convertI18nObj(plan.room_name_i18n, plan.room_name) }}
              </h4>
              <div class="hotel-orders__container rounded-sm shadow-md border-1 mb-16">
                <hotelOrder
                  :plan="plan"
                  :orderInfo="orderInfo"
                />
              </div>
            </div>
              <h6 class='mb-16'>{{ $t('cancelPolicy') }}</h6>
              <p class='whitespace-pre-wrap text-grey-dark text-sm leading-normal'>
                {{ $convertI18nObj(room[0].cancel_policy.content_i18n, room[0].cancel_policy.content | contentParser) }}
              </p>
            </div>
          </blockCard>
        </div>

        <!-- 加購體驗訂單 -->
        <div
          v-if='hasAddons'
          class="addon-orders">
          <h4 class='py-24 font-bold'>{{ $t('addAddons') }}</h4>
          <blockCard noPadding>
            <div
              v-for='(addon, key, index) in orderInfo.addons'
              :key='index'
              class="addon-orders__order py-24 lg:p-24">
              <div class="addon-orders__container rounded-sm shadow-md border-1 mb-16">
                <addonOrder :addon='addon' :orderInfo="orderInfo" />
              </div>
              <div
                v-if='hasAddonCancelPolicy(addon)'
                class="addon-orders__order__cancel-policy">
                <h6 class='mb-16'>{{ $t('cancelPolicy') }}</h6>
                <p class='whitespace-pre-wrap text-grey-dark text-sm leading-normal'>
                  {{ addon.cancel_policy | contentParser }}
                </p>
              </div>
            </div>
          </blockCard>
        </div>

        <!-- 旅館資訊 -->
        <blockCard
          class='hotel-info'
          :title="$t('hotelInfo')">
          <div class="template__info-group">
            <label>{{ $t('hotel.name') }}</label>
            <span>{{ $convertI18nObj(orderInfo.hotel.name_i18n, orderInfo.hotel.name) }}</span>
          </div>
          <div class="template__info-group" v-if="this['hotel/hotelDisplaySetting'].phone">
            <label>{{ $t('hotel.phone') }}</label>
            <span>{{ orderInfo.hotel.phone }}</span>
          </div>
          <div class="template__info-group">
            <label>{{ $t('hotel.address') }}</label>
            <span>{{ orderInfo.hotel.address }}</span>
          </div>
          <div class="hotel-info__map">
            <iframe v-if="this['hotel/hotelLocatedBy']" :src="`https://www.google.com/maps/embed/v1/place?q=${encodeURI(this['hotel/hotelLocatedBy'])}&key=${googleMapKey}`" width="100%" height="320" frameborder="0" style="border:0" allowfullscreen></iframe>
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3991.905452436695!2d121.536267949444!3d24.984534163227277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346801fed6a3ddef%3A0x55096cbf7cf14475!2z6aCk5ZOB5aSn6aOv5bqXIOaWsOW6l-WMl-aWsOmkqA!5e0!3m2!1szh-TW!2stw!4v1553081543694" width="100%" height="320" frameborder="0" style="border:0" allowfullscreen></iframe> -->
          </div>
        </blockCard>
      </div>
    </div>
  </main>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import loading from '@/components/loading'
import blockCard from '@/components/blockCard'
import hotelOrder from '@/views/Complete/components/hotelOrder'
import addonOrder from '@/views/Complete/components/addonOrder'
import paymentStatusWithCoverImg from '@/views/Complete/components/paymentStatusWithCoverImg'
import countries from '@/assets/countries'
import orderApi from '@/api/order'
import cancelWarning from '@/views/Complete/components/cancelWarning'
import cancelBankForm from '@/views/Complete/components/cancelBankForm'
import SimpleVueValidation from 'simple-vue-validator'
import fullLoading from '@/mixins/fullLoading'
import contentWithCover from '@/components/modals/contentWithCover'
import $cookies from 'js-cookie'

const Validator = SimpleVueValidation.Validator

export default {
  name: 'Complete',
  metaInfo () {
    return {
      title: this.$t('page.complete'),
      meta: [
        {
          'property': 'og:title',
          'content': this.$t('page.complete'),
          'vmid': 'og:title'
        },
        {
          'property': 'og:site_name',
          'content': this.$t('page.complete'),
          'vmid': 'og:site_name'
        }
      ]
    }
  },
  mixins: [fullLoading],
  components: {
    // allTemplate
    // processSteps
    blockCard,
    hotelOrder,
    loading,
    addonOrder,
    paymentStatusWithCoverImg
  },
  data () {
    return {
      isOrderLoading: true,
      isCancelingOrder: false,
      hasOrder: false,
      orderInfo: {},
      roomsGroupByRoomName: {},
      addonsGroupByAddonName: {},
      paymentStatus: 'unpaid',
      paymentType: '',
      cancelToken: '',
      atmPaidAccount: '',
      atmPaidDate: '',
      atmPaidTotal: '',
      isOnClikcCheckAccount: false,
      isCaptchaLoading: false,
      captchaImg: '',
      key: '',
      userCaptcha: '',
      source: ''
    }
  },
  validators: {
    'atmPaidAccount': function (value) {
      return Validator.value(value).required().digit().length(5)
    },
    'atmPaidDate': function (value) {
      return Validator.value(value).required()
    },
    'atmPaidTotal': function (value) {
      return Validator.value(value).float().required()
    },
    'userCaptcha': function (value) {
      return Validator.value(value).required()
    }
  },
  computed: {
    ...mapGetters([
      'isSuper',
      'hotel/hotelLocatedBy',
      'order/refundForm',
      'hotel/hotelDisplaySetting',
      'hotel/hotel',
      'vectorGtagUser',
      'hotel/gaIds',
      'hotel/gaConversionIds',
      'selectedLanguage'
    ]),
    ...mapGetters({
      hasExtraTaxFeeConfig: 'hotel/hasExtraTaxFeeConfig'
    }),
    diffDays: function () {
      return this.$dayjs(this.orderInfo.date_end_at).diff(this.$dayjs(this.orderInfo.date_start_at), 'day')
    },
    googleMapKey: function () {
      return process.env.VUE_APP_GOOGLE_MAP_KEY
    },
    isCancelButton: function () {
      const hasCancelToken = !_.isEmpty(this.cancelToken)
      const isNotCanceled = this.paymentStatus !== 'cancel'
      const isAllowedCancel = this.orderInfo.is_allow_user_cancel

      if (hasCancelToken && isNotCanceled && isAllowedCancel) {
        return true
      }
      return false
    },
    isDeposit: function () {
      return this.orderInfo.order_deposit_total > 0
    },
    ATMTotal: function () {
      return this.isDeposit ? this.orderInfo.order_deposit_total : this.orderTotalPriceIncludeExtraPersons
    },
    remainTotal: function () {
      return this.orderInfo.order_total - this.orderInfo.order_deposit_total
    },
    isAtmOrAtmHotel: function ({ isATM, isAtmHotel }) {
      return isATM || isAtmHotel
    },
    isATM: function () {
      return this.paymentType === 'atm'
    },
    isAtmHotel: function () {
      return this.paymentType === 'atm-hotel'
    },
    isPayAtHotel: function () {
      return this.paymentType === 'pay-at-hotel'
    },
    shouldPaymentTypeFillCancelForm: function () {
      // #4858 line payt訂購日減今日大於等於55天需要填匯款資訊
      if (this.paymentType === 'intella-linepay') {
        return this.$dayjs().diff(this.$dayjs(this.orderInfo.created_at), 'day') >= 55
      }
      const types = ['atm']
      return types.includes(this.paymentType)
    },
    dateRange: function () {
      return this.$dayjs(this.orderInfo.date_start_at).format('YYYY/MM/DD') + ' - ' + this.$dayjs(this.orderInfo.date_end_at).format('YYYY/MM/DD')
    },
    diffDaysCount: function () {
      return this.$dayjs(this.orderInfo.date_end_at).diff(this.$dayjs(this.orderInfo.date_start_at), 'day')
    },
    hasAddons: function () {
      return !_.isEmpty(this.orderInfo.addons)
    },
    countryName: function () {
      const matchedCountry = countries.find(country => country.iso2.toLowerCase() === this.orderInfo.country.toLowerCase())
      return matchedCountry ? matchedCountry.name : '-'
    },
    hasUsedCoupon: function () {
      return !_.isEmpty(this.orderInfo.coupons)
    },
    totalExtraFee: function () {
      return this.orderInfo.items?.reduce((sum, item) => {
        return sum + ((+item?.total) - (+item?.price))
      }, 0)
    },
    isNewCompleteAddonApi: function () {
      return this.orderInfo.addons[0]?.hasOwnProperty('group')
    },
    isTaiwan: function () {
      return this['hotel/hotel'].country.toLowerCase() === 'tw'
    },
    isZhTw: function () {
      return this.selectedLanguage.toLowerCase() === 'zh_tw'
    },
    orderTotalPriceIncludeExtraPersons: function () {
      if (this.orderInfo.extra_total) {
        return this.orderInfo.room_total + this.orderInfo.extra_total
      }
      return this.orderInfo.room_total
    },
    firstPaymentStatus () {
      return this.orderInfo?.first_payment_status
    },
    isFirstPaymentStatusPaid: function () {
      return this.orderInfo?.first_payment_status === 'paid'
    },
    showTransferInfo: function () {
      if (!this.isAtmOrAtmHotel) {
        return false
      }
      if (this.isAtmHotel && this.paymentStatus === 'not_finish') {
        return true
      }
      if (this.isATM && !this.isFirstPaymentStatusPaid && this.paymentStatus !== 'cancel') {
        return true
      }
      if (this.paymentStatus === 'unpaid' || this.paymentStatus === 'deposit_unpaid') {
        return true
      }

      return false
    },
    collectionPaymentMethod () {
      return [
        'applepay',
        'atm',
        'intella-linepay',
        'intella-wechatpay',
        'paypal',
        'unionpay',
        'molpay',
        'stripe-singlepay',
        'intella-alipay',
        'paynow-creditcard3d',
        'sony'
      ]
    },
    nonCollectionPaymentMethod () {
      return [
        'stripe-futurepay',
        'atm-hotel'
      ]
    },
    otherPaymentMethod () {
      return [
        'pay-at-hotel'
      ]
    },
    typeOfPaymentMethod ({ collectionPaymentMethod, nonCollectionPaymentMethod, otherPaymentMethod }) {
      const paymentMethod = this.paymentType
      if (collectionPaymentMethod.includes(paymentMethod)) {
        return 'collection'
      } else if (nonCollectionPaymentMethod.includes(paymentMethod)) {
        return 'non-collection'
      } else {
        // 'pay-at-hotel'
        return paymentMethod
      }
    },
    isShowRemainTotal () {
      const hasDeposit = this.isDeposit
      const notFailPayAndCancel = this.paymentStatus !== 'fail_pay' && this.paymentStatus !== 'cancel'
      const isCollection = this.typeOfPaymentMethod === 'collection'
      return hasDeposit && notFailPayAndCancel && isCollection
    },
    isShowDepositTotal () {
      const refundStatus = ['partial_refund', 'refund', 'wait_refund']
      return this.isFirstPaymentStatusPaid || refundStatus.includes(this.orderInfo.first_payment_status)
    },
    paidDepositTotal () {
      return this.isShowDepositTotal ? this.orderInfo.order_deposit_total : 0
    }
  },
  methods: {
    ...mapMutations([
      'SET_STEP',
      'SET_COVER_LOADING'
    ]),
    ...mapActions([
      'order/getCompletedOrder',
      'order/getCaptcha'
    ]),
    onClikcCheckAccount: function () {
      this.$validate().then(success => {
        if (!this.isOnClikcCheckAccount) {
          this.isOnClikcCheckAccount = true
          const data = {
            hotelId: this.$route.params.hotelId,
            orderSerial: this.$route.params.orderSerial,
            atmPaidAccount: this.atmPaidAccount,
            atmPaidDate: this.atmPaidDate,
            atmPaidTotal: this.atmPaidTotal,
            captcha: this.userCaptcha,
            key: this.key
          }
          if (!success) {
            this.isOnClikcCheckAccount = false
            this.$modal.show('dialog', {
              title: `<span class="text-lg text-red">${this.$t('atmHotel.validateErrorMsgTitle')}</span>`,
              text: `
              <ul class="leading-md">
                <li>${this.$t('atmHotel.valid.length')}</li>
                <li>${this.$t('atmHotel.valid.digit')}</li>
                <li>${this.$t('atmHotel.valid.format')}</li>
                <li>${this.$t('atmHotel.valid.date')}</li>
                <li>${this.$t('atmHotel.valid.price')}</li>
                <li>${this.$t('atmHotel.valid.captcha')}</li>
              </ul>
              `,
              buttons: [
                {
                  title: this.$t('close'),
                  default: true
                }
              ]
            })
          } else {
            orderApi.updateOrderCheckAccount(data).then(res => {
              this.isOnClikcCheckAccount = false
              if (res.statue === 200 || res.data.status === 0) {
                this.$modal.show('dialog', {
                  title: `<span class="text-lg text-green-dark">${this.$t(res.data.data.msg)}</span>`,
                  text: this.$t('atmHotel.updateSuccessMsg', [this.atmPaidAccount]),
                  buttons: [
                    {
                      title: this.$t('close'),
                      default: false
                    }
                  ]
                })
                this.fetchCaptcha()
              } else if (res.data.status === 120001) {
                this.$modal.show('dialog', {
                  title: `<span class="text-lg text-red">${this.$t('error')}</span>`,
                  text: this.$t('atmHotel.updateCaptchaError'),
                  buttons: [
                    {
                      title: this.$t('close'),
                      default: false
                    }
                  ]
                })
              } else {
                this.$modal.show('dialog', {
                  title: `<span class="text-lg text-red">${this.$t('error')}</span>`,
                  text: this.$t('atmHotel.updateErrorMsg'),
                  buttons: [
                    {
                      title: this.$t('close'),
                      default: false
                    }
                  ]
                })
                this.fetchCaptcha()
              }
            })
          }
        }
      })
    },
    onCancelbuttonClick: function () {
      // hotelId, orderId, cancelToken
      const today = this.$dayjs().format('YYYY-MM-DD')
      const target = this.$dayjs(this.orderInfo.date_start_at)
      this.$modal.show(cancelWarning, {
        title: `${this.$t('cancelOrderConfirmMsg')}`,
        type: 'warning',
        icon: 'owl-status-triangle-warning',
        isScroll: false,
        cancelInfo: {
          total: this.orderInfo.paid,
          diffDays: target.diff(today, 'day'),
          fee: 50,
          cancelTotal: this.orderInfo.allow_user_cancel_total,
          isDeposit: !_.isNull(this.orderInfo.deposit_rate)
        },
        buttons: [
          {
            title: this.$t('close')
          },
          {
            title: this.$t('confirm'),
            handler: () => {
              this.$modal.hide('cancelWarning')
              if (this.shouldPaymentTypeFillCancelForm) {
                this.$modal.show(cancelBankForm, {
                  buttons: [
                    {
                      title: this.$t('cancel')
                    },
                    {
                      title: this.$t('confirm'),
                      handler: () => {
                        this.$modal.hide('cancelBankForm')
                        this.isCancelingOrder = true
                        this.onCancelOrder()
                      }
                    }
                  ]
                }, {
                  adaptive: true,
                  name: 'cancelBankForm',
                  height: 'auto',
                  width: '100%',
                  maxWidth: 450,
                  scrollable: true
                })
              } else {
                this.onCancelOrder()
              }
            }
          }
        ]
      },
      {
        adaptive: true,
        name: 'cancelWarning',
        height: 'auto',
        width: '100%',
        maxWidth: 420,
        scrollable: true
      })
    },
    onCancelOrder: function () {
      this.SET_COVER_LOADING(true)
      orderApi.cancelOrder({
        hotelId: this.$route.params.hotelId,
        orderSerial: this.$route.params.orderSerial,
        cancelToken: this.cancelToken,
        bankCode: this['order/refundForm'].bank_code,
        bankSubcode: this['order/refundForm'].bank_subcode,
        bankAccount: this['order/refundForm'].bank_account,
        bankName: this['order/refundForm'].bank_name
      }).then(res => {
        if (res.status === 200) {
          if (res.data.status === 0) {
            this.$modal.show('dialog', {
              title: `<span class="text-xl text-green">${this.$t('cancelOrderSuccess')}</span>`,
              buttons: [
                {
                  title: this.$t('close')
                }
              ]
            })
          } else {
            this.$modal.show('dialog', {
              title: `<i class="owl-status-triangle-warning text-xl text-orange"></i> <span class="text-xl text-orange">${this.$t('orderCancelFailed')}</span>`,
              text: res.data.msg,
              buttons: [
                {
                  title: this.$t('close')
                }
              ]
            })
          }
        }
        this.isCancelingOrder = false
        this.isOrderLoading = true
        this.fetchOrder()
      }).finally(() => {
        this.SET_COVER_LOADING(false)
      })
    },
    roomCancelPolicy: function (policy) {
      const parseLanguage = this.$options.filters.contentParser(policy)
      return this.$options.filters.parseJSON(parseLanguage)
    },
    hasAddonCancelPolicy: function (addon) {
      return !_.isEmpty(addon.cancel_policy)
    },
    fetchOrder: function () {
      const { hotelId, orderSerial } = this.$route.params
      const params = { hotelId, orderSerial }

      this['order/getCompletedOrder'](params)
        .then(res => {
          if (res.status === 200) {
            this.orderInfo = res.data.data
            this.paymentStatus = res.data.data.status
            this.paymentType = res.data.data.payment_type
            if (res.data.data.atm_customer_check_account) {
              this.atmPaidAccount = res.data.data.atm_customer_check_account
              this.atmPaidDate = res.data.data.atm_customer_check_date
              this.atmPaidTotal = res.data.data.atm_customer_check_total
            }

            if (this.orderInfo.extra_receptions) {
              this.orderInfo.items = this.mapExtraReceptions(this.orderInfo.items, this.orderInfo.extra_receptions)
            }

            this.roomsGroupByRoomName = _.groupBy(this.orderInfo.items, function (room) {
              return room.room_name
            })

            // deprecate structure
            this.addonsGroupByAddonName = _.groupBy(this.orderInfo.addons, function (addon) {
              return addon.name
            })
            //

            this.hasOrder = true

            const paymentFlagFromCookies = $cookies.get('gtag-payment-flag')

            if (paymentFlagFromCookies === this['hotel/hotel'].hotel_uuid) {
              this.sendGtagPurchase()
              if (this['hotel/gaConversionIds']) {
                this.sendGtagConversion()
              }
              $cookies.remove('gtag-payment-flag')
            }

            // vector gtag
            // store中有符合條件的user才執行
            if (this.vectorGtagUser) {
              this.sendVectorPurchaseGtagEvent(this.vectorGtagUser)
              $cookies.remove('source')
            }
            // 反詐騙提醒
            this.showScamAlert()
          }
        }).catch(err => {
          console.debug('getCompletedOrder', err)
          this.hasOrder = false
        }).finally(() => {
          this.isOrderLoading = false
        })
    },
    couponDiscountValue: function (coupon) {
      return coupon.discount_unit === 'percent'
        ? parseInt(coupon.discount_value) + '%'
        : `${this.orderInfo.currency} ${this.$options.filters.numeral(coupon.discount_value)}`
    },
    fetchCaptcha: function () {
      this.isCaptchaLoading = true
      this['order/getCaptcha']().then(res => {
        if (res.status === 200) {
          const { data } = res
          this.captchaImg = data.img
          this.key = data.key
        }
      }).finally(() => {
        this.isCaptchaLoading = false
        this.userCaptcha = ''
      })
    },
    sendVectorPurchaseGtagEvent: function (hotelConfig) {
      this.$gtag.event('purchase', {
        send_to: [`AW-${hotelConfig.GOOGLE_CONVERSION_ID}/${hotelConfig.GOOGLE_CONVERSION_LABEL}`],
        transaction_id: this.orderInfo.order_serial,
        value: `${this.orderInfo.order_total - this.totalExtraFee}`,
        currency: this.orderInfo.currency,
        items: [{
          id: this['hotel/hotel'].name,
          start_date: this.orderInfo.date_start_at,
          end_date: this.orderInfo.date_end_at
        }]
      })
    },
    formatProductInEcommerce () {
      return this.orderInfo.items.map(item => {
        const roomName = this.$convertI18nObj(item.room_name_i18n, item.room_name)
        const planName = this.$convertI18nObj(item.plan_name_i18n, item.plan_name)
        return {
          id: `${this['hotel/hotel'].hotel_uuid}_${item.product_id}`,
          name: `${this['hotel/hotel'].name}_${roomName}_${planName}`,
          price: item.price, // 未稅價
          quantity: item.qty,
          category: 'hotel',
          brand: this['hotel/hotel'].name
        }
      })
    },
    formatAddonInEcommerce () {
      const addonData = []
      this.orderInfo.addons.forEach(item => {
        item.group.forEach(group => {
          addonData.push({
            id: `${this['hotel/hotel'].hotel_uuid}_${item.addon_id}`,
            name: `${this['hotel/hotel'].name}_${item.name}`,
            price: group.price,
            quantity: group.qty,
            category: 'addon',
            brand: this['hotel/hotel'].name
          })
        })
      })
      return addonData
    },
    getItemInEcommerceFormat () {
      let items = this.formatProductInEcommerce()
      if (this.hasAddons) {
        items = items.concat(this.formatAddonInEcommerce())
      }
      return items
    },
    sendGtagPurchase () {
      const purchaseData = {
        transaction_id: this.orderInfo.order_serial,
        value: `${this.orderInfo.order_total - this.totalExtraFee}`,
        tax: this.totalExtraFee,
        currency: this.orderInfo.currency,
        affiliation: this['hotel/hotel'].name,
        items: this.getItemInEcommerceFormat(),
        coupon: this.orderInfo.coupons.length > 0 ? this.orderInfo.coupons[0].name : ''
      }
      this.$gtag.event('purchase', purchaseData)
    },
    sendGtagConversion () {
      const conversionData = {
        value: `${this.orderInfo.order_total - this.totalExtraFee}`,
        currency: this.orderInfo.currency,
        transaction_id: this.orderInfo.order_serial
      }

      this['hotel/gaConversionIds'].forEach(id => {
        conversionData.send_to = id
        this.$gtag.event('conversion', conversionData)
      })
    },
    showScamAlert () {
      if (this.isTaiwan && this.isZhTw) {
        this.$modal.show(contentWithCover, {
          title: '反詐騙！提醒您三不原則！',
          type: 'warning',
          icon: 'owl-alert',
          isScroll: false,
          context: `
              <div class="whitespace-pre-wrap text-red">▲ 不接可疑電話號碼（例：市話號碼前面有個＋）<br><div class="mb-8 text-grey-darker">詐騙集團會篡改電信資料，轉換來電顯示或是發送簡訊。若您接獲可疑電話，請直接撥打OwlNest旅宿系統客服專線02-77276583 或 警政署反詐騙諮詢專線165 查證。切勿直接回撥顯示號碼或對方提供的號碼，以確保安全。</div>▲ 不操作ATM<br><div class="mb-8 text-grey-darker">旅宿業者不會以任何名義或理由通知更改付款方式或要求您到ATM進行任何操作(例如：ATM解除扣款、變更付款方式、補繳金額、要求轉帳、解除分期、購券刷退退款等操作)，請勿提供任何個人信用卡帳號資料、銀行帳號相關資料，並請撥打警政署反詐騙諮詢專線165或與旅宿業者聯繫再次確認。</div>▲ 不告知個人帳戶與信用卡資料<br><div class="mb-8 text-grey-darker">旅宿業者不會主動以電話請您提供信用卡或個人帳戶資料，用來解除或修改訂單付款設定；如有取消訂房之需求，請直接與旅宿聯繫。</div>`,
          buttons: [
            {
              title: this.$t('close')
            }
          ]
        },
        {
          adaptive: true,
          name: 'warninig-modal',
          clickToClose: false,
          height: 'auto',
          width: '100%',
          maxWidth: 450,
          scrollable: false
        })
      }
    },
    mapExtraReceptions (orderItem, extraReceptions) {
      extraReceptions.forEach(receptions => {
        receptions.extra_persons.forEach(extraDetail => {
          const orderItemCandidates = orderItem.filter(item => {
            const [roomId, planId] = item.product_id.split('_')
            return receptions.plan_id === +planId && receptions.room_id === +roomId && extraDetail.date === item.date
          })

          if (orderItemCandidates.length > 0) {
            const notHaveExtraPersonsItem = orderItemCandidates.find(item => !item.extra_persons)
            if (notHaveExtraPersonsItem) {
              notHaveExtraPersonsItem.extra_persons = extraDetail
            }
          }
        })
      })
      return orderItem
    }
  },
  activated () {
    // this['SET_STEP'](4)
    if (!_.isEmpty(this.$route.query.cancel)) {
      this.cancelToken = this.$route.query.cancel
    }

    this.fetchOrder()
    this.fetchCaptcha()
  },
  deactivated () {
    // clear all store once order complete
    if (!this.isSuper) {
      window.location.reload()
    }
  }
}
</script>

<style lang='sass' scoped>
.template__info-group
  @apply flex flex-col
  label
    @apply text-base text-grey-darkest mb-8
  span
    @apply text-sm text-grey-dark
  &:not(:last-of-type)
    @apply mb-24

.transfer-info
  .flex-table
    @apply text-sm text-grey-darkest
    &__row
      @apply flex mb-8

.order-info
  &__detail
    &__amounts
      .template__info-group
        &:not(:last-of-type)
          @apply mb-24
        @screen md
          @apply flex-1 mb-0 #{!important}
          &:not(:first-of-type)
            @apply pl-24
          &:not(:last-of-type)
            @apply border-r-1 border-grey-lighter
    .used-coupons
      .coupon-container
        // &:not(:last-of-type)
        .coupon-detail
          @screen md
            @apply mr-16

        .coupon-detail
          border-left: solid 8px #a9d341
          @apply flex items-center justify-between
          label
            @apply text-sm m-0 text-grey-darker
          span
            @apply text-base text-green-darker

.hotel-orders
  border-color: #f2f4f6
  &__order
    &:not(:last-of-type)
      @apply border-b-1 border-grey-lighter

.addon-orders
  &__order
    &:not(:last-of-type)
      @apply border-b-1 border-grey-lighter
.captcha
  position: relative
  margin-left: 2px
  &--loading
    &::before
      content: 'Loading...'
      color: black
      padding: 8px 24px
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 93%
      z-index: 20
      background-color: white
      opacity: 0.7
</style>

<style>
.modal__content-width-cover__content__title {
  color: #EA5E2E;
}
</style>
